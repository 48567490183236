@import url("https://fonts.googleapis.com/css2?family=Mulish&display=swap");

#root {
 min-height: 100vh;
}

* {
  -webkit-box-sizing: border-box;
  box-sizing: border-box; 
}

hr {
  border: 0.3px solid #CED4DA;
  margin-top: 35px;
  margin-bottom: 35px;
}

body {
  margin: 0;
  font-family: Mulish, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #F1F3F5;
  letter-spacing: 0.2px;
  line-height: 20px;
}

a {
  color: inherit;
  text-decoration: inherit;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

/* The below lines are needed to override react-draft-wysiwyg colorpicker for task #4805 */

.rdw-colorpicker-cube {
  border-radius: 11px;
}

.rdw-colorpicker-option:hover {
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

.rdw-colorpicker-option {
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none
}

.rdw-colorpicker-modal-options {
  -ms-overflow-style: "none"; /* for Internet Explorer, Edge */
  -ms-scroll-rails: "none";
  -ms-scrollbar-base-color: "transparent";
  scrollbar-width: "none"; /* for Firefox */
  scrollbar-base-color: "transparent";
  scrollbar-color: "transparent transparent";
  display: "none" ;/* for Chrome, Safari, and Opera */
  scrollbar-track-color: "transparent";
  scrollbar-width: "0px";
  overflow: hidden;
}

.rdw-colorpicker-modal-options > div {
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none
}

.rdw-colorpicker-modal {
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}
div.rdw-colorpicker-modal > span > span.rdw-colorpicker-modal-style-label-active {
  border-bottom: 2px solid #FF5D45;
}

/* The above lines are needed to override react-draft-wysiwyg colorpicker for task #4805 */

/* AG-Grid styles overridden */
.ag-overlay {
  height: 100%;
  position: relative!important;
}